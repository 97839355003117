export const formatReviews = (data, key = 'allMarkdownRemark') =>
  data[key].nodes.map(format);

export const formatReview = data => format(data.markdownRemark);

function format({
  id,
  fields: { frontmattermd, ...fields },
  frontmatter,
  html,
}) {
  return {
    id,
    ...fields,
    ...frontmatter,
    description: frontmattermd.description.html,
    summary: frontmattermd.summary.html,
    body: html,
  };
}
