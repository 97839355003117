import React, { useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { formatReviews } from '../formatters/review';
import { formatManufacturers } from '../formatters/manufacturer';
import Link from './Link';
import NavLinks from './NavLinks';
import HamburgerButton from './HamburgerButton';
import logoUrl from '../images/logo.svg';
import styles from './Nav.module.scss';

const Nav = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          siteName
        }
      }
      reviews: allMarkdownRemark(
        filter: {
          frontmatter: { template: { eq: "review" }, hidden: { ne: true } }
        }
        sort: { order: DESC, fields: [frontmatter___score] }
      ) {
        nodes {
          ...ReviewFragment
        }
      }
      manufacturers: allMarkdownRemark(
        filter: {
          frontmatter: {
            template: { eq: "manufacturer" }
            hidden: { ne: true }
          }
        }
      ) {
        nodes {
          ...ManufacturerFragment
        }
      }
    }
  `);

  const reviews = formatReviews(data, 'reviews');
  const manufacturers = formatManufacturers(data, 'manufacturers');

  const { siteName } = data.site.siteMetadata;

  const [navExpanded, setNavExpanded] = useState(false);

  return (
    <nav className="navbar" role="navigation" aria-label="main navigation">
      <div className="container">
        <div className="navbar-brand">
          <Link className="navbar-item" to="/">
            <img className={styles.logo} src={logoUrl} alt={siteName} />
          </Link>

          {/* eslint-disable jsx-a11y/anchor-is-valid */}
          <HamburgerButton
            active={navExpanded}
            onClick={() => setNavExpanded(!navExpanded)}
          />
          {/* eslint-enable jsx-a11y/anchor-is-valid */}
        </div>

        {navExpanded && (
          <div onClick={() => setNavExpanded(false)}>
            <NavLinks reviews={reviews} manufacturers={manufacturers} />
          </div>
        )}

        <div id="navbarBasicExample" className="navbar-menu">
          <div className="navbar-start">{/**/}</div>
          <NavLinks reviews={reviews} manufacturers={manufacturers} />
        </div>
      </div>
    </nav>
  );
};

export default Nav;
