export const formatManufacturers = (data, key = 'allMarkdownRemark') =>
  data[key].nodes.map(format);

export const formatManufacturer = data => format(data.markdownRemark);

function format({ id, fields, frontmatter, html, ...other }) {
  return {
    id,
    ...fields,
    ...frontmatter,
    body: html,
    ...other,
  };
}
