import React from 'react';
import PropTypes from 'prop-types';

// since it contains global styling, keep the import above any other components
import './Layout.scss';

import Nav from './Nav';
import Footer from './Footer';

const Layout = ({ children }) => {
  return (
    <>
      <Nav />
      <main>{children}</main>
      <Footer />
      <div />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
