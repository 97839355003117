import React from 'react';
import cls from 'classnames';
import styles from './HamburgerButton.module.scss';

const HamburgerButton = ({ active, onClick }) => (
  <button
    className={cls(styles.root, active && styles.active)}
    type="button"
    onClick={onClick}
  >
    <div className={styles.bar} />
    <div className={styles.bar} />
    <div className={styles.bar} />
  </button>
);

export default HamburgerButton;
