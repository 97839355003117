import React from 'react';
import Link from './Link';

const NavLinks = ({ reviews, manufacturers }) => (
  <div className="navbar-end">
    <Link className="navbar-item" to="/" activeClassName="is-active">
      Best VIN Decoders
    </Link>
    <div className="navbar-item has-dropdown is-hoverable">
      <span className="navbar-link">By Car Make</span>
      <div className="navbar-dropdown">
        {manufacturers.map(manufacturer => (
          <Link
            key={manufacturer.id}
            className="navbar-item"
            activeClassName="is-active"
            to={`/${manufacturer.slug}`}
          >
            {manufacturer.title}
          </Link>
        ))}
      </div>
    </div>

    <div className="navbar-item has-dropdown is-hoverable">
      <span className="navbar-link">VIN Decoder Reviews</span>

      <div className="navbar-dropdown">
        {reviews.map(review => (
          <Link
            key={review.id}
            className="navbar-item"
            activeClassName="is-active"
            to={review.path}
          >
            {review.title}
          </Link>
        ))}
      </div>
    </div>
    <Link className="navbar-item" to="/comparisons" activeClassName="is-active">
      Comparisons
    </Link>
    <Link className="navbar-item" to="/articles" activeClassName="is-active">
      Articles
    </Link>
  </div>
);

export default NavLinks;
