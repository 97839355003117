import React from 'react';
import cls from 'classnames';
import { graphql, useStaticQuery } from 'gatsby';
import Link from './Link';
import logoUrl from '../images/logo-footer.svg';
import styles from './Footer.module.scss';

const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          siteName
          emails {
            info
          }
        }
      }
    }
  `);

  const { siteName, emails } = data.site.siteMetadata;
  return (
    <footer className={cls('footer', styles.root)}>
      <div className="container columns has-text-white">
        <div className="content column is-one-quarter column-gap">
          <Link to="/">
            <img className={styles.logo} src={logoUrl} alt={siteName} />
          </Link>
          <p className="is-size-6-desktop is-size-7-touch is-marginless">
            © {new Date().getFullYear()}. All rights reserved.
          </p>
          <p className="is-size-6-desktop is-size-7-touch">
            By using our content, products & services you agree to our{' '}
            <Link
              className={cls('has-text-weight-bold', styles.termsPolicy)}
              to="/page/terms-of-use"
            >
              Terms of Use
            </Link>{' '}
            and{' '}
            <Link
              className={cls('has-text-weight-bold', styles.termsPolicy)}
              to="/page/privacy-policy"
            >
              Privacy Policy
            </Link>.
          </p>
          <p className="is-size-6-desktop is-size-7-touch">
            Contact us:{' '}
            <a
              className={cls(
                'is-size-6-desktop is-size-7-touch has-text-weight-bold',
                styles.contact,
              )}
              href={`mailto:${emails.info}`}
            >
              {emails.info}
            </a>
          </p>
        </div>
        <div className="content column is-three-quarters">
          <div className="columns is-multiline">
            <div className="column is-full">
              <div id="navbarBasicExample" className="has-flex">
                <div className="navbar-start has-text-weight-bold">
                  <Link
                    className={cls('navbar-item', styles.item)}
                    to="/page/about"
                  >
                    About us
                  </Link>

                  <Link
                    className={cls('navbar-item', styles.item)}
                    to="/page/disclosure-of-interests"
                  >
                    Disclosure of Interests
                  </Link>

                  <Link
                    className={cls('navbar-item', styles.item)}
                    to="/page/terms-of-use"
                  >
                    Terms of Use
                  </Link>

                  <Link
                    className={cls('navbar-item', styles.item)}
                    to="/page/privacy-policy"
                  >
                    Privacy Policy
                  </Link>
                </div>
              </div>
            </div>
            <div className="column is-full">
              <p className="is-size-6-desktop is-size-7-touch">
                The main objective of this website is to help users to find the
                best VIN decoder for their needs. It contains a wide range of
                services and products and lets visitors to compare them with
                each other. However, VIN checking platforms are online tools.
                Therefore, specific details, including but not limited to prices
                and special offers, are dynamic and subject to change at any
                time without prior notice. We strain every effort in monitoring
                and research, but the information provided doesn’t constitute
                legal or professional advice or forecast, and should not be
                treated as such.
              </p>
              <p className="is-size-6-desktop is-size-7-touch">
                Reproduction of this website in whole or in part is strictly
                prohibited.
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
